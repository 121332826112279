import * as React from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';

// Components
import { Collapsible } from '../Components/Common/Collapsible';
import ContactCta from '../Components/Sitewide/ContactUs/ContactCta';
import { ContentContainer } from '../Components/Common/ContentContainer';
import { HeroBanner } from '../Components/Common/HeroBanner';
import SupplierSlider from '../Components/Common/SupplierSlider';
import { BrandedTitle } from '../Components/Common/BrandedTitle';
import TeamMembersGrid from '../Components/Common/Team/TeamMembers/TeamMembersGrid';
import WeAreCertified from '../Components/Common/WeAreCertified';

import { Loading } from '../Components/Common/Loading';
import { Error } from '../Components/Common/404';
import { Paragraph } from '../Components/Common/Styles/Typography';

// Common Styled Components
import { Flexed, Article } from '../Components/Common/Styles/globalStyles';
import { graphql, useStaticQuery } from 'gatsby';

// const SEARCH_QUERY = gql`
//     query Page($id: ID!) {
//         page(idType: URI, id: $id) {
// 			uri
// 			title
// 			content
// 			pageWhyUsCustomFields {
// 				workflow
// 				steps {
// 					text
// 					title
// 				}
// 			}
//         }
//     }
// `;

const WhyUsPage: React.FC = () => {
	const { data } = useStaticQuery<GatsbyTypes.WhyUsQuery>(graphql`
	query WhyUs {
		data: wordpress {
			page(idType: URI, id: "why-us") {
				uri
				title
				content
				pageWhyUsCustomFields {
					workflow
					steps {
						text
						title
					}
				}
			}	
		}
	}
`);

	// const { loading, error, data } = useQuery(SEARCH_QUERY, {
	// 	fetchPolicy: "no-cache",
	// 	variables: { id: '/why-us/' }
	// });

	// if (loading) return <Loading />;
	// if (error) return <Error />;

	return (
		<>
			<HeroBanner pageSlug="why-us" />

			<>
				<ContentContainer>
					<Flexed>
						<Article>
							<BrandedTitle>Who We Are</BrandedTitle>

							{data?.page?.content && (
								<Paragraph.Block text={data.page.content}></Paragraph.Block>
							)}
						</Article>
						<ContactCta
							title={'Ready to Talk'}
							summary={'Ready to chat about what your business needs or have any questions on how we can help?'}
						/>
					</Flexed>
				</ContentContainer>
			</>

			<OurWorkflow backgroundImageUrl={'http://pointsafedev.wpengine.com/wp-content/uploads/2021/04/iStock-1074890376@2x.png' || ''}>
				<ContentContainer>
					<BrandedTitle>Our Workflow?</BrandedTitle>
					<Flexed>
						<Article>
							{data?.page?.pageWhyUsCustomFields?.workflow && (
								<p dangerouslySetInnerHTML={{ __html: data.page.pageWhyUsCustomFields.workflow }}></p>
							)}
							{
								data?.page?.pageWhyUsCustomFields?.steps && (
									data.page.pageWhyUsCustomFields.steps.map((step, index) => (
										<Collapsible key={index} title={step.title} alt altArrow>
											<div className="content-inner">
												<div className="inner-content">
													<p>{step.text}</p>
												</div>
											</div>
										</Collapsible>
									))
								)
							}
						</Article>
						<WeAreCertified />
					</Flexed>
				</ContentContainer>
			</OurWorkflow>


			<MeetTheTeamStyles>
				<ContentContainer>
					<BrandedTitle>Meet the team</BrandedTitle>
					<p className="centered">Your experience and dedicated team of experts</p>
					<TeamMembersGrid />
				</ContentContainer>
			</MeetTheTeamStyles>


			<SupplierSlider />
		</>
	)
}


const OurWorkflow = styled.section<{ backgroundImageUrl: string }>`
    position: relative;
    background: url('${props => props.backgroundImageUrl}'), ${props => props.theme.colors.nearlyNavy};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-blend-mode: multiply;
    padding: 16px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        padding: 64px 128px;
	}

	p:first-of-type {
		font-size: 20px;
		line-height: 24px;
		font-weight: 300;
	}

	section {
		padding: 16px 0;

		> :first-child {
			padding-right: 0;
		}
	}

    h2 {
        margin-left: 0 !important;
        text-align: left;
        font-size: 40px;
        font-weight: 400;
    }

    .content-container {
        background: var(--mobyDickWhite);
        margin: 0 auto;
        padding: 48px 32px !important;
        max-width: 1180px;

        @media (min-width: ${props => props.theme.screensizes.large}) {
            padding: 64px 94px !important;
        }
	}
	
	.collapsible {
		.title {
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
		}

		.inner-content, .inner-content > * {
			font-size: 16px;
			line-height: 20px;
		}
	}
`;

const MeetTheTeamStyles = styled.div`
 	background: ${props => props.theme.colors.almostWhite};
	padding:30px 0px;

    h2 {
        margin:30px 0px;
	}

    p.centered {
        font-size:20px;
        line-height:25px;
        margin-bottom:30px;
    }
`;

export default WhyUsPage