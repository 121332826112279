import * as React from 'react';
import styled  from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import placeholder from '../../../../images/member-placeholder.png';
import { Collapsible } from '../../../Common/Collapsible';

const TeamMemberStyles = styled.div`
    display:flex;
    flex-direction:column;
    flex:0 0 100%;
    margin-top:30px;
    max-width: 392px;
    height: 100%;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        flex:0 0 calc(50% - 10px);
    }

    @media (max-width: ${props => props.theme.screensizes.medium}) {
        margin:0 auto 20px auto;
    }

    .img-wrapper {
        position: relative;
        line-height: 0;
        height: 365px;
        overflow: hidden;
        box-shadow: 4px 4px 6px #1D234033;
        transition: 1s ease-in-out all;
        background: var(--mobyDickWhite);

        &:before {
            transition: 1s ease-in-out all;
            content: '';
            position:absolute;
            background: #1d2340 0 0 no-repeat padding-box;
            mix-blend-mode: color;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &.is-open:before {
            background: transparent;
        }

        img {
            max-width:100%;
            height: 100%;
        }
    }


    .collapsible {
        border-top: 1px solid ${props => props.theme.colors.casperGrey};
        margin-top:10px;
        [class^="Collapsible__StyledCollapsibleTitle"] {
            padding: 10px 0;
        }
    }

     .collapsible--open {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #D2D3D9;

        [class^="Collapsible__StyledCollapsibleTitle"] {
            border-bottom: initial;
        }
    }
    
    p {
        font-family: ${props => props.theme.fonts.din};
    }

    .title {
        font-size:24px;
        margin-top:10px;
        font-weight:normal;
        text-transform: capitalize;
        margin-bottom: 10px;
    }

    .sub-title {
        font-weight:bold;
        font-family: ${props => props.theme.fonts.dinNarrow};
        margin-top:10px;
        margin-bottom:10px;
        text-transform:uppercase;
    }

    .inner-content p {
        margin-bottom: 20px;
        line-height: 20px;
        font-weight: 300;
    }

    &:not(.is_featured) {
        [class^="Collapsible__StyledCollapsibleTitle"],
        [class^="Collapsible__StyledCollapsibleContent"] {
            background: var(--mobyDickWhite);
            padding: 0 8px;
        }

        [class^="Collapsible__StyledCollapsibleTitle"] {
            padding-top: 16px;
        }
    }

    &.is_featured {
        background: white;
        box-shadow: 4px 4px 6px #1D23401A;
        margin-top:10px;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex-direction: row;
            max-width:816px;
            justify-content:space-between;
            flex: 0 0 100%;
        }

        .img-wrapper {
            flex: 0 0 calc(50% - 20px);
            box-shadow:none;
            height:100%;

            &:before {
                background: transparent;
            }
        }

        .title {
            margin-bottom: 10px;
        }

        .content-inner {
            flex: 0 0 50%;
            padding: 10px 30px;

            @media (min-width: ${props => props.theme.screensizes.medium}) {
                padding: 30px 30px 30px 0px;
            }
        }

        .inner-content p:first-of-type {
            font-size:20px;
            line-height:25px;
            margin-bottom:20px;
        }
    }
`;

 
const TeamMember = ({member:member})  => {
    const [isOpen, setOpen] = React.useState(false);
    const excerpt = ReactHtmlParser(member?.excerpt);
    const content = ReactHtmlParser(member?.content);
    const imageUrl = member?.featuredImage?.node.sourceUrl ? member?.featuredImage?.node.sourceUrl : placeholder;
    const isFeatured = member?.singleTeamMember?.isFeatured;

    console.log('isOpen', isOpen)

    return (
        <TeamMemberStyles className={isFeatured ? 'is_featured' : ''}>
           <div className={`img-wrapper ${isOpen ? 'is-open' : ''}`} onClick={() => setOpen(!isOpen)}>
                <img src={imageUrl} alt={member.title} className="team-member__image" />
            </div>
            {
                isFeatured ? (
                    <div className="content-inner">
                        <div className="header-section">
                            <p className="title">{member.title}</p>
                            <p className="sub-title">{member?.singleTeamMember?.position}</p>
                        </div>

                        <div className="inner-content">
                            {excerpt}
                            {content && (content)}
                        </div>
                    </div>

                ) : (
                    <div onClick={() => setOpen(!isOpen)}>
                        <Collapsible
                            title={member.title}
                            subTitle={member?.singleTeamMember?.position}
                            altArrow
                            open={ isOpen }
                            setOpen={setOpen}
                        >
                            <div className="content-inner">
                                <div className="inner-content">
                                    {content && (content)}
                                </div>
                            </div>
                        </Collapsible>
                    </div>
                )
            }
        </TeamMemberStyles>
    )
}

export default TeamMember;
