import * as React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import TeamMember from './TeamMember';



const StyledTeamMemberGrid = styled.div`
    display:flex;
    flex-wrap:wrap;
    max-width:816px;
    margin:0 auto;
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        justify-content: space-between;
    }
`;


const TeamMembersGrid = ()  => {
    const { teamMembers } = useStaticQuery(graphql`
        query {
            teamMembers:  wordpress {
                teamMembers(first: 100, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
                    nodes {
                        databaseId
                        title
                        excerpt
                        content
                        singleTeamMember {
                            position
                            isFeatured
                        }
                        featuredImage {
                            node {
                                sourceUrl(size: POST_THUMBNAIL)
                            }
                        }
                    }
                }
            }
        }
    `);

    const members = teamMembers?.teamMembers?.nodes;
    return (
        <StyledTeamMemberGrid>
        {
            members.map(member => (
                <TeamMember key={member?.databaseId} member={member}/>
            ))
        }

        </StyledTeamMemberGrid>
    )
}

export default TeamMembersGrid
